import {
  addMinutes,
  endOfDay,
  isAfter,
  isSameDay,
  isWithinInterval,
  parseISO,
  startOfDay,
  subDays,
} from "date-fns";

import { EtaError } from "./constants";

export interface IsValidEtaParams {
  time: Date;
  shiftStart: string;
  maxEtaBoundInMinutes: number;
}
export interface IsValidEtaResponse {
  isValid: boolean;
  error?: EtaError;
}

function getEtaError(params: IsValidEtaParams): EtaError {
  const { time, shiftStart, maxEtaBoundInMinutes } = params;
  const lowerBound = parseISO(shiftStart);
  const upperBound = addMinutes(lowerBound, maxEtaBoundInMinutes);

  if (!isSameDay(lowerBound, upperBound) || isAfter(time, upperBound)) {
    return EtaError.OUT_OF_RANGE;
  }

  return EtaError.INVALID_ETA;
}

export function isValidEta(params: IsValidEtaParams): IsValidEtaResponse {
  const { time, shiftStart, maxEtaBoundInMinutes } = params;
  const lowerBound = parseISO(shiftStart);
  let upperBound = addMinutes(lowerBound, maxEtaBoundInMinutes);

  const etaSuggestion = isSameDay(time, lowerBound) ? time : subDays(time, 1);

  if (isSameDay(lowerBound, upperBound)) {
    const isValid = isWithinInterval(time, { start: lowerBound, end: upperBound });
    return { isValid, error: isValid ? undefined : getEtaError(params) };
  }

  upperBound = subDays(upperBound, 1);
  const startOfTheDay = startOfDay(upperBound);
  const endOfTheDay = endOfDay(lowerBound);

  const isInSameDayRange = isWithinInterval(etaSuggestion, {
    start: lowerBound,
    end: endOfTheDay,
  });
  const isInNextDayRange = isWithinInterval(etaSuggestion, {
    start: startOfTheDay,
    end: upperBound,
  });

  const isValid = isInSameDayRange || isInNextDayRange;

  return { isValid, error: isValid ? undefined : getEtaError(params) };
}
