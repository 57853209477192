import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// eslint-disable-next-line no-restricted-imports
import moment from "moment-timezone";
import { useController } from "react-hook-form";

import { EtaError } from "./constants";

interface Props {
  name: string;
  timezone?: string;
  maxEtaBoundInMinutes: number;
  defaultEta: Date;
}
export function EtaTimePicker(props: Props) {
  const { name, timezone, maxEtaBoundInMinutes, defaultEta } = props;
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({ name });
  /**
   * FIXME: After https://github.com/mui/mui-x/issues/10573 is addressed, refactor to
   * remove `moment` and use `Date` + `date-fns`
   */
  const etaMoment = moment(field.value as Date);

  const getEtaErrorMessage = (message?: string) => {
    switch (message) {
      case EtaError.NO_ETA: {
        return `Please enter an ETA`;
      }

      case EtaError.OUT_OF_RANGE: {
        return `ETA can't be ${maxEtaBoundInMinutes} minutes past the start time of your shift. Try again.`;
      }

      case EtaError.INVALID_ETA: {
        return `Invalid ETA. Try again!`;
      }

      default: {
        return undefined;
      }
    }
  };

  return (
    <Box>
      <Text variant="subtitle2">ETA for shift</Text>
      {/**
       * Timezone don't work in date-fns for MUI. hence we rely on AdapterMoment
       * FIXME: After https://github.com/mui/mui-x/issues/10573 is addressed, refactor to
       * use DateFns Adapter.
       */}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopTimePicker
          {...field}
          /**
           * FIXME, add a label to the input field.
           * Placeholder is not a valid alternative for a label since it disappears with data entry.
          label="ETA"
          */
          value={etaMoment}
          closeOnSelect={false}
          disablePast={false}
          timezone={timezone}
          disabled={isSubmitting}
          slotProps={{
            textField: {
              fullWidth: true,
              helperText: getEtaErrorMessage(error?.message),
              error: isDefined(error?.message),
              name: "eta",
              required: true,
            },
          }}
          onChange={(eta) => {
            /**
             * Use the defaultEta if the user clears all the fields.
             * Otherwise, the provided date will be decreased by one day.
             **/
            field.onChange(eta ? eta.toDate() : defaultEta);
          }}
        />
      </LocalizationProvider>
    </Box>
  );
}
